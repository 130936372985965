import { template as template_09c49979be084a79955eccd3e8145d6a } from "@ember/template-compiler";
const FKLabel = template_09c49979be084a79955eccd3e8145d6a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
