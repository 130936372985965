import { template as template_690100442d8a47cca0ec60d6b3c18640 } from "@ember/template-compiler";
const FKText = template_690100442d8a47cca0ec60d6b3c18640(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
