import { template as template_b0b806d8981245dcb9233c99db7e7a1b } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
import getURL from "discourse-common/lib/get-url";
import { i18n } from "discourse-i18n";
const BackToForum = template_b0b806d8981245dcb9233c99db7e7a1b(`
  <a href={{getURL "/"}} class="sidebar-sections__back-to-forum">
    {{icon "arrow-left"}}

    <span>{{i18n "sidebar.back_to_forum"}}</span>
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackToForum;
